import React from 'react';
import {
    TextField,
    Grid,
    Typography
} from "@material-ui/core";
import { useStateValue } from "../../StateContext";

const ServiceForm = () => {

    const [{ formValues }, dispatch] = useStateValue();

    return <>
        <Grid item xs={12}>
            <Typography variant="h6">Estimated Time of Arrival</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                type="date"
                label="When will you start?"
                name="arrivaldate"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                value={formValues.arrivaldate}
                onChange={e =>
                    dispatch({
                        type: 'editFormValue',
                        key: "arrivaldate",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Service"
                name="service"
                variant="outlined"
                fullWidth
                value={formValues.service}
                onChange={e =>
                    dispatch({
                        type: 'editFormValue',
                        key: "service",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h6">Who has worked with you?</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Staff Member"
                name="staff"
                variant="outlined"
                fullWidth
                value={formValues.staff}
                onChange={e =>
                    dispatch({
                        type: 'editFormValue',
                        key: "staff",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="FSM"
                name="fsm"
                variant="outlined"
                fullWidth
                value={formValues.fsm}
                onChange={e =>
                    dispatch({
                        type: 'editFormValue',
                        key: "fsm",
                        value: e.target.value
                    })
                }
            />
        </Grid>
    </>
}

export default ServiceForm;